<template>
  <el-card class="card" shadow="never">
    <div v-if="title" slot="header">
      <slot name="header">
        <el-tooltip :disabled="!tooltip" placement="top" class="tooltip">
          <div slot="content" v-html="tooltip"></div>
          <b>{{ title }}</b>
        </el-tooltip>
      </slot>
      <div class="actions">
        <span>
          <QsFillingRate
            :qlik-id="fillingRateQlikId"
            v-if="fillingRateQlikId"
            class="action"
          />
          <date-range
            v-if="date"
            class="date-range action"
            :field="date.field"
            :disable-future-dates="date.disableFutureDates"
          />
          <el-button
            v-if="!hideMenu"
            type="text"
            @click="toggleOverlayMenu"
            style="color: black"
            class="action"
          >
            <fa
              :icon="['far', overlayMenuOpen ? 'times' : 'ellipsis-v']"
              size="lg"
              fixed-width
            />
          </el-button>
        </span>
      </div>
    </div>
    <div
      class="body"
      :class="{ 'with-header': title, [cardStyle]: cardStyle }"
      @click="
        title &&
          $mixpanel.track(`Click '${title}' chart`, {
            chartCategory: title
          })
      "
    >
      <slot></slot>
    </div>
  </el-card>
</template>

<script>
import DateRange from "@/components/Qlik/DateRange";
import QsFillingRate from "@/components/Qlik/QsFillingRate.vue";

export default {
  name: "Card",
  components: { DateRange, QsFillingRate },
  props: {
    qlikId: { type: String, default: "" },
    hideMenu: { type: Boolean, default: false },
    title: String,
    tooltip: String,
    date: Object,
    cardStyle: {
      type: String,
      validator: cardStyle => ["compact"].includes(cardStyle)
    },
    fillingRateQlikId: { type: String, default: "" }
  },
  inject: {
    context: { default: () => ({ overlayMenuOpen: false }) }
  },
  computed: {
    overlayMenuOpen() {
      return this.context.overlayMenuOpen;
    }
  },
  methods: {
    toggleOverlayMenu() {
      const { context, overlayMenuOpen } = this;
      Object.assign(context, { overlayMenuOpen: !overlayMenuOpen });
    }
  }
};
</script>

<style scoped>
.card {
  position: relative;
}

.actions {
  position: absolute;
  top: 0;
  right: 20px;
  height: 55px;
  display: flex;
  align-items: center;
}

.date-range {
  display: inline-block;
}

.action {
  margin-left: 5px;
}

.body {
  position: absolute;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 10px;
}

.body.compact {
  padding: 4px;
}

.body.with-header {
  top: 55px;
}
</style>
