<template>
  <span>
    <el-tooltip placement="top" class="tooltip">
      <div slot="content">Indice de fiabilité: {{ filling.percent }}%</div>
      <span :style="`color: ${filling.color}`"><fa icon="circle" /></span>
    </el-tooltip>
    <span :id="qlikDomId" style="display: none" />
  </span>
</template>

<script>
import { get, uniqueId } from "lodash";
import { createNamespacedHelpers } from "vuex";
const { mapState } = createNamespacedHelpers("qlik");

const getRate = qlikObject =>
  get(
    qlikObject,
    ["layout", "qHyperCube", "qDataPages", 0, "qMatrix", 0, 0, "qText"],
    "0"
  ).replace(",", ".");

export default {
  props: { qlikId: { type: String, default: "" } },
  data: () => ({ qlikObject: null }),
  mounted() {
    this.app
      .getObject(this.qlikDomId, this.qlikId)
      .then(qlikObject => (this.qlikObject = qlikObject));
  },
  computed: {
    ...mapState(["app"]),
    qlikDomId: () => `q${uniqueId()}`,
    filling() {
      const percent = (getRate(this.qlikObject) * 100).toFixed(1);

      if (percent <= 20) return { color: "#e53e3e", percent };
      if (percent <= 50) return { color: "orange", percent };
      return { icon: "full", color: "#48BB77", percent };
    }
  },
  beforeDestroy() {
    if (!this.qlikObject) return;
    this.app
      .destroySessionObject(this.qlikId)
      .then(() => this.qlikObject.close())
      .then(() => (this.qlikObject = null));
  }
};
</script>

<style scoped></style>
