<template>
  <div class="block">
    <el-date-picker
      size="small"
      @change="filter"
      v-model="selectedValue"
      type="daterange"
      align="right"
      range-separator="au"
      start-placeholder="Date de début"
      end-placeholder="Date de fin"
      format="dd/MM/yyyy"
      :picker-options="pickerOptions"
    ></el-date-picker>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState } = createNamespacedHelpers("qlik");

export default {
  props: {
    field: String,
    disableFutureDates: {
      type: Boolean,
      default: false
    }
  },
  data() {
    var disabledDate = () => false;
    if (this.disableFutureDates) {
      disabledDate = date => {
        const today = new Date();
        if (date > today) {
          return true;
        } else {
          return false;
        }
      };
    }
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "Les 7 derniers jours",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "Les 30 derniers jours",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "Les 90 derniers jours",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ],
        disabledDate: disabledDate
      },
      selectedValue: ""
    };
  },
  computed: mapState(["app"]),
  mounted() {
    this.app.selectionState().OnData.bind(() => {
      this.onSelectionUpdate();
    });
    this.onSelectionUpdate();
  },
  methods: {
    onSelectionUpdate() {
      const fieldSelection = this.app
        .selectionState()
        .selections.find(row => row.fieldName === this.field);
      if (fieldSelection) {
        try {
          const dates = fieldSelection.qTextSearch.split(">=")[1].split("<=");
          this.selectedValue = [
            this.toUSFormat(dates[0]),
            this.toUSFormat(dates[1])
          ];
        } catch (error) {
          this.selectedValue = "";
        }
      } else {
        this.selectedValue = "";
      }
    },
    toUSFormat(dateString) {
      const dmy = dateString.split("/");
      return `${dmy[2]}/${dmy[1]}/${dmy[0]}`;
    },
    dateToString(date) {
      // getDate(): 1-31, getMonth: 0-11
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    },
    filter() {
      const field = this.app.field(this.field);
      if (!this.selectedValue) {
        field.clear();
      } else {
        const start = this.dateToString(this.selectedValue[0]);
        const end = this.dateToString(this.selectedValue[1]);
        field.selectMatch(`>=${start}<=${end}`, false);
      }
    }
  }
};
</script>

<style scoped>
.el-picker-panel__shortcut {
  padding-bottom: 10px;
  padding-right: 12px;
  line-height: 15px !important;
}

.el-date-editor >>> .el-range-separator {
  width: 26.39px;
}
</style>
