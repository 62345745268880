<template>
  <div id="TicketingExpertSteering">
    <qs-kpi-grid
      title="Cette semaine"
      :columns="6"
      :qlik-ids="['LqNvYDR', 'jtwHHT', 'MLxWVH', 'Pmsqt', 'mytsmpX', 'XBdPh']"
    />

    <qs-kpi-grid
      title="Ce mois"
      :columns="6"
      :qlik-ids="['vxfAJ', 'MJMAKF', 'zNsm', 'XqhPAYW', 'vZPCxX', 'tRjnJz']"
    />

    <qs-kpi-grid
      title="Cette année"
      :columns="6"
      :qlik-ids="['PtbyG', 'ErUmgms', 'XVKSbT', 'FcPEpj', 'XxBzyqY', 'LWjnqS']"
    />
  </div>
</template>

<script>
import QsKpiGrid from "@/components/Qlik/QsKpiGrid";

export default {
  components: { QsKpiGrid },
  mounted() {
    this.$mixpanel.track("View steering page");
  }
};
</script>

<style scoped>
#TicketingExpertSteering {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 200px);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: 20px;
}
</style>
