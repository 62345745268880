<template>
  <card :title="title" :tooltip="tooltip" :hide-menu="true">
    <div class="grid-kpis" :style="gridStyle">
      <qs-kpi v-for="qsKpi in qsKpis" :key="qsKpi.qlikId" v-bind="qsKpi" />
    </div>
  </card>
</template>

<script>
import Card from "@/components/Card";
import QsKpi from "@/components/Qlik/QsKpi";

export default {
  components: { Card, QsKpi },
  props: {
    title: String,
    tooltip: String,
    qlikIds: Array,
    columns: {
      type: Number,
      default: 2
    },
    preservesOnDestroy: { type: Array, default: () => [] }
  },
  data() {
    return { columnSize: "1fr " };
  },
  computed: {
    gridStyle() {
      return `grid-template-columns: ${this.columnSize.repeat(this.columns)};`;
    },
    qsKpis() {
      const { qlikIds, preservesOnDestroy } = this;

      return qlikIds.map(qlikId => ({
        qlikId,
        preserveOnDestroy: preservesOnDestroy.includes(qlikId)
      }));
    }
  }
};
</script>

<style scoped>
.grid-kpis {
  height: 100%;
  display: grid;
  grid-template-rows: auto;
  justify-items: center;
  align-items: center;
}
</style>
